import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, fromEvent } from 'rxjs';
import { map, catchError, tap, window } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../core/alert.service';
import { enviromentProd } from '../../environments/environment.prod';
import { Location } from "@angular/common";


@Injectable()
export class BackendService {
  urlApi = ""
  constructor(private http: HttpClient, private router: Router, private alert: AlertService, private loc:Location) {
    // if(this.loc["_platformStrategy"]._platformLocation.location.hostname.split('.').includes("ampiguadalajara"))
    // this.urlApi = enviromentProd.ampi
    // if(this.loc["_platformStrategy"]._platformLocation.location.hostname.split('.').includes("mlsampisma"))
    // this.urlApi = enviromentProd.smg
    if (localStorage.getItem("server") == "gdl")
      this.urlApi = enviromentProd.ampi
    if (localStorage.getItem("server") == "smg")
      this.urlApi = enviromentProd.smg
  }
  /* 
*Sesion
*/
  iniciarSession(data) {
    this.obtenerApikey(data).subscribe(
      result => {
        this.iniciarUsuario(data.correo, result['data'].apikey)
      },
      (err) => {

        this.alert.showNotification('top', 'right', `${err.error.message}`, 4)
        // this.obtener_mensajes_error(err);
      }
    )
  }

  obtenerApikey(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    return this.http.post(`${this.urlApi}usuarios:obtener_apikey`, data, httpOptions)
  }

  iniciarUsuario(usuario, apikey) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    let body = { correo: usuario, apikey: apikey }

    this.http.post(`${this.urlApi}sesion:iniciar`, body, httpOptions).subscribe(result => {
      localStorage.setItem('JWT', result['data'].jwt)
      localStorage.setItem('usuario', JSON.stringify(result['data'].usuario))
      // if (!result['data'].usuario.correo_valido) {
      result['data'].usuario.roles[0] == 'root'
        ? this.router.navigate(['/inicio'])
        : this.getRol(result['data'].usuario.id)
      // }
      // else
      //   localStorage.clear()
      //   localStorage.setItem('activateToken', result['data'].jwt)
      //   this.router.navigate(['/authentication/activateAccount']);
    },
      (err) => {
        this.obtener_mensajes_error(err);
      })
  }

  cerrarSession() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    };
    let data = {};

    return this.http.post(`${this.urlApi}sesion:cerrar`, data, httpOptions).subscribe(
      result => {
        localStorage.removeItem('JWT');
        localStorage.removeItem('usuario');
        localStorage.removeItem('inmobiliaria');
        localStorage.removeItem('perfil');
        localStorage.removeItem('permits');
        localStorage.removeItem('asesor');
        localStorage.removeItem('inmobiliaria_nombre');        
        // localStorage.clear()
        this.router.navigate(['/authentication/login']);
      },
      (err) => {
        localStorage.removeItem('JWT');
        localStorage.removeItem('usuario');
        localStorage.removeItem('inmobiliaria');
        localStorage.removeItem('perfil');
        localStorage.removeItem('permits');
        localStorage.removeItem('asesor');
        localStorage.removeItem('inmobiliaria_nombre');
        this.router.navigate(['/authentication/login']);
      }
    )
  }

  recuperarContrasena(correo) {
    let httpOptions =
    {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post(`${this.urlApi}usuarios:recuperar_contrasena`, correo, httpOptions)
  }

  crearContrasena(usuario) {
    let httpOptions =
    {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post(`${this.urlApi}usuarios:modificar_contrasena_recuperar`, usuario, httpOptions)
  }

  modificarContrasena(usuario) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}usuarios:modificarcontrasena`, usuario, httpOptions)
  }

  getRol(id) {
    let filter = { filter: { where: [{ field: "usuario_id", operator: "=", value: id }] } }
    this.get(filter, 'asesores').subscribe(res => {
      if (res['data'].length)
        res['data'].map(res => {
          const { inmobiliaria_id, perfil,permisos } = res
          localStorage.setItem('inmobiliaria', inmobiliaria_id)
          localStorage.setItem('perfil', perfil)
          localStorage.setItem('permits', permisos)
          this.router.navigate(['/inicio'])
          // this.router.navigate(['/inicio'])
        })
      else {
        this.alert.showNotification('top', 'right', 'Este usuario no cuenta con  permisos para acceder', 4)
        this.cerrarSession()
      }
    })
  }

  //Generics
  get(filter: {}, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}${endpoint}:consultar`, filter, httpOptions)
  }

  obtener(filter: {}, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    console.log('====================================');
    console.log(filter);
    console.log('====================================');
    return this.http.post(`${this.urlApi}${endpoint}:obtener`, filter, httpOptions)
  }

  post(data, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}${endpoint}:crear`, data, httpOptions)
  }

  update(data, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}${endpoint}:actualizar`, data, httpOptions)
  }

  delete(data, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}${endpoint}:eliminar`, data, httpOptions)
  }

  media(data) {
    console.log(data);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}media:cargar_base`, data, httpOptions)
  }

  like(inmueble_id) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}inmuebles:like_toggle`, { inmueble_id }, httpOptions)
  }

  vinculacion(data, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}biblioteca:${endpoint}`, data, httpOptions)
  }

  vincular(data, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}inmuebles:${endpoint}`, data, httpOptions)
  }

  notis() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}usuarios:notificaciones`, { limit: 10 }, httpOptions)
  }

  request(data, endpoint) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('activateToken')}`
      })
    }
    return this.http.post(`${this.urlApi}${endpoint}`, data, httpOptions)
  }

  getExcel(endpoint, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }
    return this.http.post(`${this.urlApi}${endpoint}`, data, { ...httpOptions, responseType: 'blob' })
  }

  getPdf(endpoint, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }
    return this.http.post(`${this.urlApi}${endpoint}`, data, { ...httpOptions })
  }

  comentarios(endpoint, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}${endpoint}`, data, httpOptions)
  }

  adminPass(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}usuarios:modificar_contrasena_admin`, data, httpOptions)
  }

  dolarValue() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}dollar:consultar`,{}, httpOptions)
  }


  // mailing
  createCamp(title,data,user){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    let mails={
      adri:{name:"Adriana Rodríguez",mail:"atencion@ampiguadalajara.com.mx"},
      mari:{name:"Marisela Pérez",mail:"administracion@ampiguadalajara.com.mx"},
      jorge:{name:"Administración",mail:"webmaster@ampiguadalajara.com.mx"},
    }

    let campData = {      
        "name": "Campaña Ampi",
        "subject": title,
        "from_name": mails[user].name,
        "from_email": mails[user].mail,
        "content": data
    }

    return this.http.post(`https://acacia.stacktree.mx/mailing/ampi-mail/campaigns`,campData, httpOptions)
  }

  sendCamp(id){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }
    
    return this.http.post(`https://acacia.stacktree.mx/mailing/ampi-mail/campaigns/${id}/enviar`,{"segments": [3]}, httpOptions)
  }

  sendSurvey(data){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}encuestas:crear`,data, httpOptions)
  }

  getBit(data){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('JWT')}`
      })
    }

    return this.http.post(`${this.urlApi}bitacoras:consultar`,data, httpOptions)
  }

  // /*Unidades de almacenaje */
  // consultarUnidades(filtros) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/unidades_almacenaje:consultar`, filtros, httpOptions)
  // }

  // agregarUnidadAlmacenaje(unidad) {
  //   let httpOptions =
  //   {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/usuarios:agregar_unidad_almacenaje`, unidad, httpOptions)
  // }

  // /*Activos */

  // consultarActivos(filtros) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/activos:consultar`, filtros, httpOptions)
  // }

  // traspasarUnidad(unidad) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/activos:traspaso`, unidad, httpOptions)
  // }

  // salidaActivo(activo) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/activos:salida`, activo, httpOptions)
  // }

  // /*Codigos */
  // actualizarCodigos(body) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/activos:modificar_codigos`, body, httpOptions)
  // }

  // modificarAtributos(body) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/activos:modificar_atributos`, body, httpOptions)
  // }

  // /*Productos */
  // consultarProductos(filtros) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/productos:consultar`, filtros, httpOptions)
  // }

  // agregarProductos(produto) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/productos:modificar`, produto, httpOptions)
  // }

  // registrarActivo(activo) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/activos:entrada`, activo, httpOptions)
  // }

  // /*Almacenes */
  // consultarAlmacenes(filtros) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/unidades_almacenaje:consultar`, filtros, httpOptions)
  // }

  // modificarAlmacenes(unidad) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/unidades_almacenaje:modificar`, unidad, httpOptions)
  // }

  // eliminarUnidadAlmacenaje(unidad) {
  //   let httpOptions =
  //   {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/unidades_almacenaje:eliminar`, unidad, httpOptions)
  // }

  // //Media
  // cargarArchivos(body, servicio) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   switch (servicio) {
  //     case 'docActivo':
  //       return this.http.post(`${this.urlApi}/activos:agregar_documento`, body, httpOptions)

  //     case 'mediaActivo':
  //       return this.http.post(`${this.urlApi}/activos:agregar_media`, body, httpOptions)

  //     case 'docProducto':
  //       return this.http.post(`${this.urlApi}/productos:agregar_documento`, body, httpOptions)

  //     case 'mediaProducto':
  //       return this.http.post(`${this.urlApi}/productos:agregar_media`, body, httpOptions)

  //   }
  // }

  // eliminarMultimedia(categoria, tipo, media) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }
  //   switch (categoria) {
  //     case 'Activo':
  //       if (tipo == 'documento')
  //         return this.http.post(`${this.urlApi}/activos:eliminar_documento`, media, httpOptions)
  //       else
  //         return this.http.post(`${this.urlApi}/activos:eliminar_media`, media, httpOptions)
  //     case 'Producto':
  //       if (tipo == 'documento')
  //         return this.http.post(`${this.urlApi}/productos:eliminar_documento`, media, httpOptions)
  //       else
  //         return this.http.post(`${this.urlApi}/productos:eliminar_media`, media, httpOptions)
  //   }

  // }

  // /*Usuarios */
  // // consultarUsuarios() {
  // //   let httpOptions =
  // //   {
  // //     headers: new HttpHeaders({
  // //       'Content-Type': 'application/json',
  // //     })
  // //   }
  // //   let body = {}

  // //   return this.http.post(`${this.urlApi}/usuarios:modificarcontrasenarecuperar`, body, httpOptions)
  // // }

  // consultarUsuarios(usuario) {
  //   let httpOptions =
  //   {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/usuarios:consultar`, usuario, httpOptions)
  // }

  // modificarUsuario(usuario) {
  //   let httpOptions =
  //   {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/usuarios:modificar`, usuario, httpOptions)
  // }

  // eliminarUsuario(usuario) {
  //   let httpOptions =
  //   {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/usuarios:modificarcontrasenarecuperar`, usuario, httpOptions)
  // }

  // /*Productos */
  // obtenerProducto(producto) {
  //   let httpOptions =
  //   {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/productos:consultar`, producto, httpOptions)
  // }

  // eliminarProducto(producto) {
  //   let httpOptions =
  //   {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('JWT')}`
  //     })
  //   }

  //   return this.http.post(`${this.urlApi}/productos:eliminar`, producto, httpOptions)
  // }

  /*
  *Utilidades
  */
  obtener_mensajes_error(err) {
    let mensaje_error = ''
    if (err.error.errors)
      Object.keys(err.error.errors).map(msj => {
        mensaje_error += err.error.errors[msj][0] + "\r\n"
      })

    // if (!mensaje_error) {
    //   this.alert.showNotification('top', 'right', err.error.message, 4)
    //   return
    // } else {
    //   this.alert.showNotification('top', 'right', mensaje_error, 4)
    // }
  }

  beLimpiarLs() {
    localStorage.clear()
    this.router.navigate(['/authentication/login']);
  }


  jalarImagen() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9uZXd0b24ua2xheXdhcmUuY29tXC9zZW1hZGV0XC9zZXNpb246aW5pY2lhciIsImlhdCI6MTU3NTM4NDAyOSwiZXhwIjoxNTc1NDcwNDI5LCJuYmYiOjE1NzUzODQwMjksImp0aSI6IkJBVkVBRjVhbWNKQ2UyR20iLCJzdWIiOjI0LCJwcnYiOiIxMGFjOTc3ZGM5YjdkNzI3NDQ5Y2Y5MzM3MTcyYWEzZTM2YjFmYjViIiwic3NuIjoiMTVZR3lRMmhyeFFDU3RMWU44RjB6TXhFYWpRUmt6NmI1TnNUWXRkWSIsImdyZCI6IlNlbWFkZXQifQ.YWeZAF534tae0xwFHFhHiyyjEFrEmjJffsX2VPBGn8Q`
      })
    }

    return this.http.get(`http://newton.klayware.com/semadet/archivos:obtener?file=ezoaFqKeMeENntdHKwz9RYZVEJ.jpeg`, httpOptions)
  }
}
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu{
  state: string;
  name: string;
  type: string;
  icon: string;
  perfiles: any[];
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}
/*
*class: 'hide-submenu'
*este parametro nos ayuda a agregar una opcion al menu de forma oculta 
*y asi poder utilizar la ruta en el panel de contenido y al dar clic el menu quede como activo
*/
const MENUITEMS = [
// {
//   state: 'inicio',
//   name: 'Ingresar código',
//   type: 'link',
//   icon: 'assets/images/newTemplate/1_codigo.png',
//   whiteIcon:'assets/images/newTemplate/1_codigo_white.png',
//   permission: ['action:activos','action:activos,consultar']
// },
// {
//   state: 'almacenaje',
//   name: 'Unidades de almacenaje',
//   type: 'link',
//   icon: 'assets/images/newTemplate/2_unidad.png',
//   whiteIcon:'assets/images/newTemplate/2_unidad_white.png',
//   permission: ['action:unidades_almacenaje']
// },
// {
//   state: 'productos',
//   name: 'Productos',
//   type: 'link',
//   icon: 'assets/images/newTemplate/3_productos.png',
//   whiteIcon:'assets/images/newTemplate/3_productos_white.png',
//   permission: ['action:productos']
// },
{
  state: 'comunicacion',
  name: 'Comunicación',
  type: 'sub',
  icon: 'apps',
  children: [
    { state: 'editorial', name: 'Editorial', permission: [''] },
    // { state: 'aliados', name: 'Aliados', permission: [''] },
    { state: 'calendario', name: 'Calendario', permission: [''] },
    { state: 'convenios', name: 'Convenios', permission: [''] },
    { state: 'servicios', name: 'Servicios', permission: [''] },
    { state: 'videos', name: 'Videos', permission: [''] },
    { state: 'biblioteca', name: 'Biblioteca', permission: [''] },
    { state: 'mailing', name: 'Campañas', permission: [''] }

  ],
  permission: ['']
},
{
  state: 'web',
  name: 'Sitio Web',
  type: 'sub',
  icon: 'apps',
  children: [
    { state: 'banners', name: 'Banners', permission: [''] },
    { state: 'ampi', name: 'Ampi', permission: [''] },
    // { state: 'biblioteca', name: 'Biblioteca', permission: [''] },
    { state: 'contacto', name: 'Contacto', permission: [''] },
    { state: 'enlaces', name: 'Enlaces de interés', permission: [''] },
  ],
  permission: ['']
},
{
  state: 'usuarios',
  name: 'Usuarios',
  type: 'link',
  icon: 'assets/images/newTemplate/4_user.png',
  whiteIcon:'assets/images/newTemplate/4_user_white.png',
  permission: ['']
},
{
  state: 'inmobiliarias',
  name: 'Inmobiliarias',
  type: 'link',
  icon: 'assets/images/newTemplate/4_user.png',
  whiteIcon:'assets/images/newTemplate/4_user_white.png',
  permission: ['']
},
{
  state: 'colonias',
  name: 'Colonias',
  type: 'link',
  icon: 'assets/images/newTemplate/4_user.png',
  whiteIcon:'assets/images/newTemplate/4_user_white.png',
  permission: ['']
},
{
  state: 'reportes',
  name: 'Reportes',
  type: 'link',
  icon: 'assets/images/newTemplate/4_user.png',
  whiteIcon:'assets/images/newTemplate/4_user_white.png',
  permission: ['']
},
];

@Injectable()

export class MenuItems {
  //getMenuitem(): Menu[] {
  getMenuitem(){
    return MENUITEMS;
  }

}

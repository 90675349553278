import { Routes } from '@angular/router';
import { LoginGuard } from './login.guard';
import { NoLoginGuard } from './no-login.guard';

//importar vistas (components)
import { FullComponent } from './layouts/full/full.component';

import { AppBlankComponent } from './layouts/blank/blank.component';
import { CambiarContrasenaComponent } from './perfil/cambiar-contrasena/cambiar-contrasena.component';

export const AppRoutes: Routes = [
{
  path: '',
  component: FullComponent,
  children: [
  {
    path: '',
    redirectTo: '/authentication/login',
    pathMatch: 'full'
  }, 
  {
    path: 'profile/:id',
    loadChildren: './profile/profile.module#ProfileModule',
  },
  {
    path: 'inicio',
    loadChildren: './main/main.module#MainModule'
  }, 
  {
    path: 'buscador',
    loadChildren: './searcher/searcher.module#SearcherModule'
  },  
  {
    path: 'propiedades',
    loadChildren: './properties/properties.module#PropertiesModule'
  },
  {
    path: 'comunicacion',
    loadChildren: './comunicacion/comunicacion.module#ComunicacionModule'
  }, 
  {
    path: 'web',
    loadChildren: './website/website.module#WebsiteModule'
  }, 
  {
    path: 'usuarios',
    loadChildren: './usuarios/usuarios.module#UsuariosModule'
  },
  {
    path: 'inmobiliarias',
    loadChildren: './inmobiliarias/inmobiliarias.module#InmobiliariasModule'
  },
  {
    path: 'colonias',
    loadChildren: './colonias/colonias.module#ColoniasModule'
  },
  {
    path: 'reportes',
    loadChildren: './reportes/reportes.module#ReportesModule'
  },
  {
    path: 'cambiar-contrasena',
    component: CambiarContrasenaComponent,
  },
  {
    path: 'sample',
    loadChildren: './sample/sample.module#SampleModule'
  },
  ],
  canActivate:[LoginGuard],
},
// {
//   // component: AppBlankComponent,
  
    
  
  
//   canActivate:[LoginGuard],
// },
{
  path: '',
  component: AppBlankComponent,
  children: [{
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  },
  {
    path: 'authentication/lockscreen',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }],
  canActivate:[NoLoginGuard]
  ,
  
}, 
{
  path: '**',
  redirectTo: 'authentication/404'
}];

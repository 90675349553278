import { Component } from '@angular/core';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BackendService } from '../../../service/backend.service';
import * as moment from 'moment'
import { StorageService } from '../../../utils/languajeService'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  isVisible = false;
  notis = []
  constructor(private ls:StorageService,public rest: BackendService) {
    this.getNotis()
  }
languaje = {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.ls.watchStorage().subscribe((data:string) => {
      this.languaje = localStorage.getItem("languaje")
      })

  }
  logOut() {
    this.isVisible = true;
    this.rest.cerrarSession();
  }

  getNotis() {
    let notis = []
    this.rest.notis().subscribe(r => {
      r['data'].map(r => {
        const { id, data: { evento,message }, created_at } = r
        notis.push({ id, evento,message, created_at:moment(created_at).format('DD-MM-YYYY: h:m:s') })
      })
      this.notis = notis
      console.log(this.notis)
    })
  }

  setLanguaje(languaje){
    this.ls.setItem("languaje",languaje)
    console.log(localStorage.getItem("languaje"));
    
  }

  getVersion(){
    window.location.reload(true);
  }
}
